import queryString from "query-string"

/**
 * Отформатировать телефонный номер, для отправки на сервер
 * @param {string} phone - Сильно отформатированный телефонный номер - "+7 (952) 519-07-51"
 * @returns {string} - Менее отформатированный телефонный номер - "+79525190731"
 */
export function preparePhoneNumberForServerRequest(phone) {
	return `+${phone.replace(/\D/g, "")}`
}

/**
 * Скрыть 5 символов-цифр телефонного номера с конца, оставляя 2 последние открытыми
 * @param {string} phone
 * @returns {string}
 */
export function concealPhone(phone) {
	let counter = 0
	let replacer = ""

	const hiddenNumbersIndex = phone.length - 3

	for (let i = hiddenNumbersIndex; ; i--) {
		if (counter === 5) break

		if (!isNaN(parseInt(phone[i]))) {
			counter++
			replacer += "X"
		} else {
			replacer += phone[i]
		}
	}

	return (
		phone.slice(0, hiddenNumbersIndex - replacer.length + 1) +
		replacer
			.split("")
			.reverse()
			.join("") +
		phone.slice(hiddenNumbersIndex + 1, phone.length)
	)
}

/**
 * В зависимости от переданного числа, возвращает окончание слово "день"
 * с корректным окончанием
 * @param {number} daysCount - кол-во дней
 * @returns {string}
 */
export function getEndOfDaysWord(daysCount) {
	// Переменная row содержит возможные последние цифры в значении `daysCount`,
	// при которых в сочетании со словом "день" последнему подставляется нужное окончание.
	// (например: 2 дня, 53 дня, 344 дня и т.п.)
	const row = "234"
	const lastIndex = daysCount.length - 1
	const penultIndex = daysCount.length - 2

	if (daysCount[lastIndex] === "1" && daysCount[penultIndex] !== "1") {
		return " день"
	} else if (
		row.indexOf(daysCount[lastIndex]) !== -1 &&
		daysCount[penultIndex] !== "1"
	) {
		return " дня"
	} else {
		return " дней"
	}
}

/**
 * Функция для конвертации параметров из url в js-объект
 * @param {string} urlParamsString
 * @returns {Object}
 */
export function convertUrlParamsToObject(urlParamsString) {
	return queryString.parse(urlParamsString)
}

/**
 * Функция для вставки виджета обратного звонка сервиса "Mango".
 */
export function insertMangoCallback() {
	const script = document.createElement("script")
	script.async = true
	script.innerHTML = `
              !function(t) {
                  function e() {
                      i = document.querySelectorAll(".button-widget-open");
                      for (var e = 0; e < i.length; e++) "true" != i[e].getAttribute("init") && (options = JSON.parse(i[e].closest('.' + t).getAttribute("data-settings")), i[e].setAttribute("onclick", "alert('" + options.errorMessage + "(0000)'); return false;"))
                  }

                  function o(t, e, o, n, i, r) {
                      var s = document.createElement(t);
                      for (var a in e) s.setAttribute(a, e[a]);
                      s.readyState ? s.onreadystatechange = o : (s.onload = n, s.onerror = i), r(s)
                  }

                  function n() {
                      for (var t = 0; t < i.length; t++) {
                          var e = i[t];
                          if ("true" != e.getAttribute("init")) {
                              options = JSON.parse(e.getAttribute("data-settings"));
                              var o = new MangoWidget({
                                  host: window.location.protocol + '//' + options.host,
                                  id: options.id,
                                  elem: e,
                                  message: options.errorMessage
                              });
                              o.initWidget(), e.setAttribute("init", "true"), i[t].setAttribute("onclick", "")
                          }
                      }
                  }

                  host = window.location.protocol + "//lk.mango-office.ru/";
                  var i = document.getElementsByClassName(t);
                  o("link", {
                      rel: "stylesheet",
                      type: "text/css",
                      href: host + "widget/widget-button.css"
                  }, function() {
                  }, function() {
                  }, e, function(t) {
                      document.documentElement.insertBefore(t, document.documentElement.firstChild)
                  }), o("script", { type: "text/javascript", src: host + "js/widget/m.c.w-min.js" }, function() {
                      ("complete" == this.readyState || "loaded" == this.readyState) && n()
                  }, n, e, function(t) {
                      document.documentElement.appendChild(t)
                  })
              }("mango-callback");
          `
	document.body.appendChild(script)
}

/**
 * Функция возвращает значение куки с указанным 'name'
 * @param {string} name
 */
export function getCookiesName(name) {
	const matches = document.cookie.match(
		new RegExp(
			"(?:^|; )" +
				name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
				"=([^;]*)"
		)
	)
	return matches ? decodeURIComponent(matches[1]) : undefined
}
