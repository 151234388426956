exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about_company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-automation-avtomatizaciya-bara-js": () => import("./../../../src/pages/automation/avtomatizaciya-bara.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-bara-js" */),
  "component---src-pages-automation-avtomatizaciya-fastfood-js": () => import("./../../../src/pages/automation/avtomatizaciya-fastfood.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-fastfood-js" */),
  "component---src-pages-automation-avtomatizaciya-fudtraka-js": () => import("./../../../src/pages/automation/avtomatizaciya-fudtraka.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-fudtraka-js" */),
  "component---src-pages-automation-avtomatizaciya-kafe-js": () => import("./../../../src/pages/automation/avtomatizaciya-kafe.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-kafe-js" */),
  "component---src-pages-automation-avtomatizaciya-kalyannoj-js": () => import("./../../../src/pages/automation/avtomatizaciya-kalyannoj.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-kalyannoj-js" */),
  "component---src-pages-automation-avtomatizaciya-kofejni-js": () => import("./../../../src/pages/automation/avtomatizaciya-kofejni.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-kofejni-js" */),
  "component---src-pages-automation-avtomatizaciya-konditerskoy-js": () => import("./../../../src/pages/automation/avtomatizaciya-konditerskoy.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-konditerskoy-js" */),
  "component---src-pages-automation-avtomatizaciya-kulinarii-js": () => import("./../../../src/pages/automation/avtomatizaciya-kulinarii.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-kulinarii-js" */),
  "component---src-pages-automation-avtomatizaciya-obshchepita-js": () => import("./../../../src/pages/automation/avtomatizaciya-obshchepita.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-obshchepita-js" */),
  "component---src-pages-automation-avtomatizaciya-paba-js": () => import("./../../../src/pages/automation/avtomatizaciya-paba.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-paba-js" */),
  "component---src-pages-automation-avtomatizaciya-pekarni-js": () => import("./../../../src/pages/automation/avtomatizaciya-pekarni.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-pekarni-js" */),
  "component---src-pages-automation-avtomatizaciya-piccerii-js": () => import("./../../../src/pages/automation/avtomatizaciya-piccerii.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-piccerii-js" */),
  "component---src-pages-automation-avtomatizaciya-restorana-js": () => import("./../../../src/pages/automation/avtomatizaciya-restorana.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-restorana-js" */),
  "component---src-pages-automation-avtomatizaciya-stadiona-js": () => import("./../../../src/pages/automation/avtomatizaciya-stadiona.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-stadiona-js" */),
  "component---src-pages-automation-avtomatizaciya-stolovoy-js": () => import("./../../../src/pages/automation/avtomatizaciya-stolovoy.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-stolovoy-js" */),
  "component---src-pages-automation-avtomatizaciya-sushi-js": () => import("./../../../src/pages/automation/avtomatizaciya-sushi.js" /* webpackChunkName: "component---src-pages-automation-avtomatizaciya-sushi-js" */),
  "component---src-pages-automation-dark-kitchen-js": () => import("./../../../src/pages/automation/dark-kitchen.js" /* webpackChunkName: "component---src-pages-automation-dark-kitchen-js" */),
  "component---src-pages-automation-index-js": () => import("./../../../src/pages/automation/index.js" /* webpackChunkName: "component---src-pages-automation-index-js" */),
  "component---src-pages-back-office-js": () => import("./../../../src/pages/back-office.js" /* webpackChunkName: "component---src-pages-back-office-js" */),
  "component---src-pages-capability-js": () => import("./../../../src/pages/capability.js" /* webpackChunkName: "component---src-pages-capability-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-customer-display-js": () => import("./../../../src/pages/customer-display.js" /* webpackChunkName: "component---src-pages-customer-display-js" */),
  "component---src-pages-delivery-club-js": () => import("./../../../src/pages/delivery-club.js" /* webpackChunkName: "component---src-pages-delivery-club-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-delivery-payment-js": () => import("./../../../src/pages/delivery-payment.js" /* webpackChunkName: "component---src-pages-delivery-payment-js" */),
  "component---src-pages-discounts-js": () => import("./../../../src/pages/discounts.js" /* webpackChunkName: "component---src-pages-discounts-js" */),
  "component---src-pages-emenu-js": () => import("./../../../src/pages/emenu.js" /* webpackChunkName: "component---src-pages-emenu-js" */),
  "component---src-pages-finance-js": () => import("./../../../src/pages/finance.js" /* webpackChunkName: "component---src-pages-finance-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-kds-js": () => import("./../../../src/pages/kds.js" /* webpackChunkName: "component---src-pages-kds-js" */),
  "component---src-pages-license-agreement-annex-2-js": () => import("./../../../src/pages/licenseAgreement/annex2.js" /* webpackChunkName: "component---src-pages-license-agreement-annex-2-js" */),
  "component---src-pages-license-agreement-annex-3-js": () => import("./../../../src/pages/licenseAgreement/annex3.js" /* webpackChunkName: "component---src-pages-license-agreement-annex-3-js" */),
  "component---src-pages-license-agreement-annex-js": () => import("./../../../src/pages/licenseAgreement/annex.js" /* webpackChunkName: "component---src-pages-license-agreement-annex-js" */),
  "component---src-pages-license-agreement-index-js": () => import("./../../../src/pages/licenseAgreement/index.js" /* webpackChunkName: "component---src-pages-license-agreement-index-js" */),
  "component---src-pages-line-js": () => import("./../../../src/pages/line.js" /* webpackChunkName: "component---src-pages-line-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-new-password-js": () => import("./../../../src/pages/newPassword.js" /* webpackChunkName: "component---src-pages-new-password-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-old-about-company-js": () => import("./../../../src/pages/old/about_company.js" /* webpackChunkName: "component---src-pages-old-about-company-js" */),
  "component---src-pages-partner-program-js": () => import("./../../../src/pages/partnerProgram.js" /* webpackChunkName: "component---src-pages-partner-program-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-partners-new-js": () => import("./../../../src/pages/partners-new.js" /* webpackChunkName: "component---src-pages-partners-new-js" */),
  "component---src-pages-passkit-js": () => import("./../../../src/pages/passkit.js" /* webpackChunkName: "component---src-pages-passkit-js" */),
  "component---src-pages-price-assets-sale-icon-tsx": () => import("./../../../src/pages/price/assets/SaleIcon.tsx" /* webpackChunkName: "component---src-pages-price-assets-sale-icon-tsx" */),
  "component---src-pages-price-compare-js": () => import("./../../../src/pages/price/compare.js" /* webpackChunkName: "component---src-pages-price-compare-js" */),
  "component---src-pages-price-index-js": () => import("./../../../src/pages/price/index.js" /* webpackChunkName: "component---src-pages-price-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-qrm-js": () => import("./../../../src/pages/qrm.js" /* webpackChunkName: "component---src-pages-qrm-js" */),
  "component---src-pages-reg-js": () => import("./../../../src/pages/reg.js" /* webpackChunkName: "component---src-pages-reg-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-robots-txt-js": () => import("./../../../src/pages/robots.txt.js" /* webpackChunkName: "component---src-pages-robots-txt-js" */),
  "component---src-pages-secret-dobrocoffee-js": () => import("./../../../src/pages/secret/dobrocoffee.js" /* webpackChunkName: "component---src-pages-secret-dobrocoffee-js" */),
  "component---src-pages-secret-index-js": () => import("./../../../src/pages/secret/index.js" /* webpackChunkName: "component---src-pages-secret-index-js" */),
  "component---src-pages-secret-kroshka-li-js": () => import("./../../../src/pages/secret/kroshka-li.js" /* webpackChunkName: "component---src-pages-secret-kroshka-li-js" */),
  "component---src-pages-secret-molodo-zeleno-js": () => import("./../../../src/pages/secret/molodo-zeleno.js" /* webpackChunkName: "component---src-pages-secret-molodo-zeleno-js" */),
  "component---src-pages-secret-ohmybowl-js": () => import("./../../../src/pages/secret/ohmybowl.js" /* webpackChunkName: "component---src-pages-secret-ohmybowl-js" */),
  "component---src-pages-secret-park-47-js": () => import("./../../../src/pages/secret/park47.js" /* webpackChunkName: "component---src-pages-secret-park-47-js" */),
  "component---src-pages-secret-pervyj-nos-js": () => import("./../../../src/pages/secret/pervyj-nos.js" /* webpackChunkName: "component---src-pages-secret-pervyj-nos-js" */),
  "component---src-pages-secret-sezony-js": () => import("./../../../src/pages/secret/sezony.js" /* webpackChunkName: "component---src-pages-secret-sezony-js" */),
  "component---src-pages-secret-shchegol-js": () => import("./../../../src/pages/secret/shchegol.js" /* webpackChunkName: "component---src-pages-secret-shchegol-js" */),
  "component---src-pages-secret-virazh-js": () => import("./../../../src/pages/secret/virazh.js" /* webpackChunkName: "component---src-pages-secret-virazh-js" */),
  "component---src-pages-secret-zolotoj-zhuk-js": () => import("./../../../src/pages/secret/zolotoj-zhuk.js" /* webpackChunkName: "component---src-pages-secret-zolotoj-zhuk-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-terminal-js": () => import("./../../../src/pages/terminal.js" /* webpackChunkName: "component---src-pages-terminal-js" */),
  "component---src-pages-warehouse-js": () => import("./../../../src/pages/warehouse.js" /* webpackChunkName: "component---src-pages-warehouse-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-wl-application-order-js": () => import("./../../../src/pages/WLApplicationOrder.js" /* webpackChunkName: "component---src-pages-wl-application-order-js" */),
  "component---src-pages-wl-js": () => import("./../../../src/pages/wl.js" /* webpackChunkName: "component---src-pages-wl-js" */),
  "component---src-pages-yandex-js": () => import("./../../../src/pages/yandex.js" /* webpackChunkName: "component---src-pages-yandex-js" */)
}

